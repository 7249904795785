form {
  @include card-box;
  @extend .position-relative;
  @extend .margin-bottom-20;
  @extend .overflow-hidden;
  @extend .position-relative;

  .form83-header {
    @extend .padding-bottom-12;
    @extend .border-bottom-1;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .height-55;
    @extend .padding-15;

    .form83-title {
      @include size-color(16px, $text-color);
      @extend .margin-0;
      @extend .align-items-center;

      span {
        @include size-color(14px, $themeColorPrimary);
        @extend .margin-left-7;
      }

      i {
        @extend .flex-center-align;
        @extend .margin-right-10;
      }
    }

    h6 {
      @extend .margin-0;
      @include size-color(16px, $text-color);
      @extend .margin-0;
      @extend .align-items-center;

      i {
        @extend .flex-center-align;
        @extend .margin-right-10;
      }

      span {
        @include size-color(14px, $themeColorPrimary);
        @extend .margin-left-7;
      }
    }

    .form83-headers-button-group {
      @extend .display-flex;

      .button83 {
        @extend .box-shadow-0;
        @extend .margin-left-10;

        i {
          @extend .f-16;
        }
      }
    }
  }

  .form83-body {
    @extend .padding-15;
    @extend .position-relative;

    .form83-no-header {
      @extend .min-height-50;
    }
  }

  .form83-scrollable-body-lg {
    @include custom-height(calc(100vh - 300px));
    @include custom-margin-bottom(70px !important);
    @extend .overflow-y-auto;

    // &::-webkit-scrollbar {
    //   @extend .display-none;
    // }
  }

  .form83-footer {
    @extend .padding-15;
    @include custom-height(70px);
    @extend .display-flex;
    @extend .align-items-end;
    @extend .flex-wrap;
    @extend .flex-column;
    @extend .justify-content-end;
    @extend .position-absolute;
    @extend .w-100;
    @include background-white;
    @include custom-bottom(0px);

    // .form83-action-button {
    //   button {
    //     @include custom-height-width(42px, 100%);
    //   }
    // }

    h6 {
      @extend .f-14;
      @include color($text-color);
    }

    p {
      @include color($text-color);
      @extend .margin-0;
      @extend .align-items-center;

      i {
        @extend .f-17;
        @extend .margin-right-15;
      }

      span {
        @extend .f-14;
      }
    }
  }

  .form83-action-buttons {
    @extend .w-100;

    .button83 {
      @extend .w-100;

      &:last-of-type {
        @extend .margin-top-12;
      }
    }
  }
}


.form83-group {
  @extend .margin-bottom-15;

  .form83-label {
    @include size-weight-color(13px, 700, $text-color);
    @extend .display-block;
    @extend .margin-bottom-5;
  }

  .form83-control-multi-select {
    @extend .w-100;
    > div {
      @include padding(3px, 0px, 3px, 0px);
    }
  }
}

.form83-control,
.form83-control-multi-select > div {
  @extend .padding-12;
  @include size-color(13px, $text-color);
  @extend .border-0;
  @extend .border-radius-6;
  @extend .height-auto;
  @include custom-line-height(normal);
  @extend .w-100;
  @include background($light-gray-bg);
  @include border(border, 1px solid $light-gray-bg !important);
  @extend .transition-5s;

  &:hover {
    @include border(border, 1px solid $border-color-dark !important);
    @extend .transition-5s;
  }

  & + div > div {
    @extend .max-height-150;
    @extend .overflow-y-auto;
  }
}

input[type="checkbox"], .form83-control-checkbox {
  @extend .align-items-center;
  @extend .justify-content-center;
  appearance: none;
  @extend .border-radius-4;
  @include border(border, 1px solid $border-color);
  @include transition-5s;
  @include background($light-gray-bg);
  @include border(border, 1px solid $border-color);
  @extend .transition-5s;
  @include custom-height-width(21px, 21px !important);
  @include transform(translateY(-0.075em));

  &:hover {
    @include border(border, 1px solid $border-color-dark !important);
  }
}

input[type="checkbox"]::before, .form83-control-checkbox::before {
  content: "";
  @include custom-height-width(0.65em, 0.65em);
  @include transform(scale(0));
  @include custom-transition(120ms transform ease-in-out);
  @include custom-box-shadow(inset 1em 1em $themeColorPrimary);
  transform-origin: bottom left;
  @include custom-height-width(12px, 12px);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before , .form83-control-checkbox:checked::before {
  @include transform(scale(1));
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  + label {
    &:before {
      content: '';
      @include background($light-gray-bg);
      @extend .circle;
      @extend .border-1;
      @extend .display-inline-block;
      @include custom-height-width(20px, 20px);
      @extend .position-relative;
      @extend .margin-right-15;
      @extend .vertical-align-top;
      @extend .cursor-pointer;
      @extend .text-center;
      @extend .transition-3s;
    }

    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
  &:checked {
    + label {
      &:before {
        @include background($themeColorPrimary);
        @include custom-box-shadow(inset 0 0 0 4px $light-gray-bg);
      }
    }
  }
  &:focus {
    + label {
      &:before {
        outline: none;
        @include background($themeColorPrimary);
      }
    }
  }
  &:disabled {
    + label {
      &:before {
        @include custom-box-shadow(inset 0 0 0 4px $light-gray-bg);
        border-color: darken($light-gray-bg, 25%);
        @include background(darken($light-gray-bg, 25%));
      }
    }
  }
}

.custom-checkboxes, .custom-radio-buttons {
  @extend .padding-0;
  @extend .margin-top-0;
  
  li {
    @extend .align-items-center;
    @extend .margin-bottom-10;
    @extend .margin-right-10;

    label {
      @extend .margin-left-7;
    }
  }
}

.sliding-form {
  animation: moveFromRightToShow 0.5s linear;
}

.custom-checkboxes,
.custom-radio-buttons {
  @extend .padding-0;
  @extend .margin-top-0;

  li {
    @extend .align-items-center;
    @extend .margin-bottom-10;
    @extend .margin-right-10;
    @include border(border, 0px !important);

    label {
      @extend .margin-left-7;
    }
  }
}