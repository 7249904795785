.footer83{
  @include size-color(13px, $text-color);
  @include flex-align-justify(center, space-between);
  @extend .padding-x-24;
  @extend .padding-y-12;
  @extend .bg-white;
  position: sticky;
  bottom: 0px;
  @extend .border-top-1;
  z-index:  1;
  
  ul {
    @extend .align-items-center;
    @extend .list-style-none;
    
    li {
      @include border(border-right, 1px solid $border-color-dark);
      @include custom-line-height(12px);
      @extend .padding-x-15;
      @include color($text-color);
      
      &:last-of-type {
        @extend .border-right-0;
        @extend .padding-right-0;
      }
      
      &:first-of-type {
        @extend .padding-left-0;
      }
      
      a {
        @include custom-line-height(12px);
        @extend .text-decoration-none;
        @include color($text-color);
        
        &:hover {
          @extend .text-underline;
        }
      }
    }
  }
}